import { NAME, TAGLINE } from "./branding";
import { CANONICAL } from "./meta";

export const AUDIO_STREAM_URL = "http://46.105.70.69:9487/stream.flac";
export const AUDIO_DATA_URL = "https://soyrage.es/nowplaying.php";
export const DEFAULT_VOLUME = 50;
export const FETCH_TRACK_INFO_INTERVAL = 5000;
export const DEFAULT_TRACK_INFO = {
	title: NAME,
	artist: TAGLINE,
	album: NAME,
	art: CANONICAL + "trickfm.png",
};